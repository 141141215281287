import { useAuth } from 'fitify-ui-onboarding/src/hooks/useAuth'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import {
  ONBOARDING_PAGES,
  ONBOARDING_PAGES_MAP,
  APP_PAGES_MAP,
  APP_PAGES,
} from 'utils/routes'
import { DataContext } from 'utils/state'

const DISABLE_APP_PAGES = APP_PAGES_MAP.map((page) => page.path)
const DISABLED_PAGES = [
  ONBOARDING_PAGES.home.path,
  ONBOARDING_PAGES.success.path,
  ...DISABLE_APP_PAGES,
]

export function useUserRedirect() {
  const router = useRouter()
  const { data, set } = useContext(DataContext)
  const { userProfile, isAuthLoading } = useAuth()

  useEffect(() => {
    const handleRedirect = () => {
      // Check if userProfile exists and redirection is allowed
      if (isAuthLoading || !userProfile) return

      // Set data state from userProfile if empty
      if (Object.keys(data).length === 0 && userProfile?.profile) {
        set({
          coach_id: userProfile.coach?.coach_id,
          ...userProfile?.profile,
        })
      }

      // Destructure properties from userProfile or set defaults
      const { subscription_hc, coach, onboarding_completed } = userProfile
      const { active: subscriptionActive } = subscription_hc || {}
      const { coach_id: selectedCoach } = coach || {}
      const hasCompletedOnboarding = onboarding_completed?.hc || false

      // Get paths of step pages from the ONBOARDING_PAGES_MAP
      const stepPages = ONBOARDING_PAGES_MAP.filter((route) => route.key).map(
        (route) => route.path
      )

      // Get the current path from the router
      const currentPath = router.pathname

      // Redirect logic based on conditions
      if (subscriptionActive && router.pathname !== APP_PAGES.gift.path) {
        // If subscription is active and a coach is selected
        router.replace(ONBOARDING_PAGES.success.path)
      } else if (stepPages.includes(currentPath) && hasCompletedOnboarding) {
        // If on a step page and onboarding is completed
        if (selectedCoach) {
          // If a coach is selected, redirect to subscription page
          router.replace(ONBOARDING_PAGES.subscription.path)
        } else {
          // If no coach is selected, redirect to coach selection page
          router.replace(ONBOARDING_PAGES.selectCoach.path)
        }
      }
    }

    if (!DISABLED_PAGES.includes(router.pathname)) {
      handleRedirect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userProfile, isAuthLoading])
}
