import { Timestamp } from 'firebase/firestore'

export type FirestoreDocumentId = string
export type WithId<T> = T & { id: FirestoreDocumentId }
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never } & U
export type XOR<T, U> = Without<T, U> | Without<U, T>

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export type SerializableTimestamp = {
  _seconds: number
  _nanoseconds: number
}

export type FirestoreTimestamp = Timestamp | SerializableTimestamp | null

export type LatLng = {
  lat: number
  lng: number
}
