import { getAnalytics, setUserId } from 'firebase/analytics'
import { FirebaseError } from 'firebase/app'
import {
  AuthErrorCodes,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  type User,
  type UserCredential,
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { RemoteConfig } from 'fitify-ui/src/hooks/useRemoteConfig'
import { logEvent } from 'fitify-ui/src/utils/analytics'
import { toast } from 'fitify-ui-onboarding/src/components/Toast'
import { getNavigatorLocale } from 'fitify-ui-onboarding/src/utils'
import { type TFunction } from 'next-i18next'

type ScheduleNotificationApp = 'workouts' | 'hc'

// Schedule notification for new users
export const scheduleNotifications = async (
  config: RemoteConfig,
  app: ScheduleNotificationApp
) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [language, country] = getNavigatorLocale().split('-')

  try {
    await httpsCallable(
      getFunctions(),
      'scheduleNotifications'
    )({
      promo_schedule: config.promo_schedule,
      notification_schedule: config.notification_schedule,
      email_schedule: config.email_schedule,
      timezone,
      language,
      country,
      app,
    })
  } catch (err) {
    console.error(err)
  }
}

// Handles and detects new user after login with social
export const handleNewUser = async (
  config: RemoteConfig,
  credentials: UserCredential | null,
  app: ScheduleNotificationApp
) => {
  const auth = getAuth()

  let userCredentials = credentials

  // Load credentials after social redirect
  if (!credentials) {
    userCredentials = await getRedirectResult(auth)
  }

  if (!userCredentials) return
  const details = getAdditionalUserInfo(userCredentials)

  if (details?.isNewUser) {
    logEvent('signup_only')
    await scheduleNotifications(config, app)
  }
}

function handleUserReceived(user: User | null) {
  const userUid = user?.uid
  if (userUid) {
    setUserId(getAnalytics(), userUid)
  }
}

/**
 * Function for handling responses to auth errors from Firebase. If 'auth/account-exists-with-different-credential'/'auth/email-already-in-use' error happens, the whole Error object is returned. In all other cases an error toast is displayed.
 */
export const handleAuthError = (
  error: unknown,
  t: TFunction
): string | null => {
  const ErrorCodes = {
    MISSING_PASSWORD: 'auth/missing-password',
  }

  if (error instanceof FirebaseError) {
    switch (error.code) {
      case AuthErrorCodes.USER_DELETED:
        toast({
          type: 'error',
          content: `${t('email_login_invalid_credentials_message')}`,
        })
        break

      case AuthErrorCodes.INVALID_PASSWORD:
        toast({
          type: 'error',
          content: `${t('email_login_invalid_credentials_message')}`,
        })
        break

      case AuthErrorCodes.INVALID_EMAIL:
        toast({ type: 'error', content: `${t('error_invalid_email')}` })
        break

      case AuthErrorCodes.EMAIL_EXISTS:
        return error.customData ? (error.customData.email as string) : ''

      case AuthErrorCodes.NEED_CONFIRMATION:
        return error.customData ? (error.customData.email as string) : ''

      case AuthErrorCodes.WEAK_PASSWORD:
        toast({ type: 'error', content: `${t('error_short_password')}` })
        break

      case ErrorCodes.MISSING_PASSWORD:
        toast({ type: 'error', content: `${t('error_short_password')}` })
        break

      default:
        toast({ type: 'error', content: `${t('login_error')}` })
        break
    }
  }

  return null
}

/**
 * Handles comeback from redirect to 3rd party identity provider for login. For more info, see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#getredirectresult
 */
export async function handleSocialMount(t: TFunction): Promise<string | null> {
  const auth = getAuth()
  try {
    const result: UserCredential | null = await getRedirectResult(auth)
    if (result) {
      handleUserReceived(result.user)
    }
  } catch (error: any) {
    return handleAuthError(error, t)
  }

  return null
}

export function handleEmailSuccess() {
  const auth = getAuth()
  handleUserReceived(auth.currentUser)
}

export function handlePasswordResetSuccess(t: TFunction) {
  toast({
    type: 'success',
    content: `${t('forgot_password_continue_email')}`,
  })
}
